import React, { useState } from 'react';
import './homepage.css'; // Ensure the path is correct
import UserRegistration from './components/UserRegistration'; // Import the user registration component
import { useNavigate } from 'react-router-dom';
import Pujaris from './pages/pujaris'; // Import the Pujaris component
import Aboutus from './pages/aboutus'; // Import the Aboutus component
import Testimonials from './pages/testimonials'; // Import the Testimonials component

function HomePage() {
    const [formState, setFormState] = useState(null); // 'signup' or 'signin'
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFormDisplay = (formType) => {
        setFormState(formType);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
                credentials: 'include' // This is important for cookies
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Login failed');
            }

            // Store the token after successful login
            localStorage.setItem('token', data.token);

            console.log('Login successful, token stored:', data.token);
            navigate('/dashboard'); // Navigate to dashboard after login
        } catch (error) {
            console.error('Error during login:', error);
            setError(error.message); // Set the error message to display to the user
        } finally {
            setLoading(false); // End loading state
        }
    };

    return (
        <div className="home">
            <div className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>PujariNet ॐ</h1>
                        <p className="catchphrase">Your Connection to Hindu Priests.</p>
                        <p className="mission-statement">Empowering families through accessible and fair booking services.
                          Register now to see all available priests and book your ceremony!
                        </p>
                        <nav className="hero-buttons">
                            <button className="button-primary" onClick={() => handleFormDisplay('signup')}>Sign Up</button>
                            <button className="button-secondary" onClick={() => handleFormDisplay('signin')}>Sign In</button>
                        </nav>
                    </div>
                </div>
                <div className="hero-right">
                    {formState && (
                        <div className="form-container">
                            {formState === 'signup' && <UserRegistration />}
                            {formState === 'signin' && (
                                <div className="signin-form">
                                    <h2>Sign In</h2>
                                    <form onSubmit={handleLogin}>
                                        <div>
                                            <label>Email:</label>
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label>Password:</label>
                                            <input
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                        <button type="submit" disabled={loading}>
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Main Content */}
            <main className="homepage-main">
                <section className="section" id="available-pujaris">
                    <Pujaris />
                </section>
                <section className="section" id="aboutus">
                    <Aboutus />
                </section>
                <section className="section" id="testimonials">
                    <Testimonials />
                </section>
            </main>
        </div>
    );
}

export default HomePage;
