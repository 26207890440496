import React from 'react';
import './pujaris.css';

const pujaris = [
  {
    id: 1,
    name: "Pandit Ramesh Sharma",
    specialization: "Vedic Rituals",
    experience: "15 years",
    languages: ["Hindi", "Sanskrit", "English"],
    image: "/pujariexample.jpg"
  },
  {
    id: 2,
    name: "Acharya Sunita Devi",
    specialization: "Bhagavad Gita Discourses",
    experience: "20 years",
    languages: ["Bengali", "Hindi", "English"],
    image: "/pujariexample.jpg"
  },
  {
    id: 3,
    name: "Swami Krishnananda",
    specialization: "Yoga and Meditation",
    experience: "25 years",
    languages: ["Tamil", "Sanskrit", "English"],
    image: "/pujariexample.jpg"
  },
  // Add more pujaris as needed
];

function Pujaris() {
  return (
    <div className="pujaris">
      <h2>Our Esteemed Pujaris</h2>
      <div className="pujari-grid">
        {pujaris.map((pujari) => (
          <div key={pujari.id} className="pujari-card">
            <img src={pujari.image} alt={pujari.name} className="pujari-image" />
            <h3>{pujari.name}</h3>
            <p><strong>Specialization:</strong> {pujari.specialization}</p>
            <p><strong>Experience:</strong> {pujari.experience}</p>
            <p><strong>Languages:</strong> {pujari.languages.join(", ")}</p>
            <button className="book-button">Book Now</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pujaris;