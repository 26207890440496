import React from 'react';
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling within the page
import { Link as RouterLink, useLocation } from 'react-router-dom'; // For routing and determining current location
import './Header.css'; // Ensure the CSS file is properly linked

function Header() {
  const location = useLocation(); // Get the current location object
  const isHomePage = location.pathname === '/'; // Check if the current path is the homepage

  return (
    <header className="header">
      <div className="logo">
        {isHomePage ? (
          <ScrollLink to="hero" smooth={true} duration={1000}>
              <img src={`${process.env.PUBLIC_URL}/pujarinetlogo.png`} alt="PujariNet Logo" />
          </ScrollLink>
        ) : (
          <RouterLink to="/">
            <img src={`${process.env.PUBLIC_URL}/pujarinetlogo.png`} alt="PujariNet Logo" />
          </RouterLink>
        )}
      </div>

      <nav className="navigation">
        <ul>
          <li>
            {isHomePage ? (
              <ScrollLink to="available-pujaris" smooth={true} duration={1000}>
                Available Pujaris
              </ScrollLink>
            ) : (
              <RouterLink to="/#available-pujaris">Available Pujaris</RouterLink>
            )}
          </li>
          <li>
            {isHomePage ? (
              <ScrollLink to="aboutus" smooth={true} duration={1000}>
                About Us
              </ScrollLink>
            ) : (
              <RouterLink to="/aboutus">About Us</RouterLink>
            )}
          </li>
          <li>
            {isHomePage ? (
              <ScrollLink to="testimonials" smooth={true} duration={1000}>
                Testimonials
              </ScrollLink>
            ) : (
              <RouterLink to="/testimonials">Testimonials</RouterLink>
            )}
          </li>
          <li>
            {isHomePage ? (
              <ScrollLink to="contact-us" smooth={true} duration={1000}>
                Contact Us
              </ScrollLink>
            ) : (
              <RouterLink to="/#contact-us">Contact Us</RouterLink>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;