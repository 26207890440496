import React from 'react';
import './testimonials.css'; // Make sure to create and link this CSS file

const testimonials = [
  {
    quote: "PujariNet made booking our wedding priest so simple and hassle-free!",
    author: "Priya & Rahul",
    role: "Newlyweds"
  },
  {
    quote: "Finding a priest who speaks our language was effortless with PujariNet!",
    author: "Sharma Family",
    role: "Satisfied Clients"
  },
  {
    quote: "The platform's user-friendly interface made planning our puja a breeze.",
    author: "Anita Desai",
    role: "Grateful User"
  }
  // Add more testimonials as needed
];

function Testimonials() {
  return (
    <div className="testimonials-section">
      <div className="testimonials-container">
        <h2 className="section-title">What Our Users Say</h2>
        <div className="testimonial-list">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="quote-icon">"</div>
              <p className="quote">{testimonial.quote}</p>
              <div className="author-info">
                <p className="author">{testimonial.author}</p>
                <p className="role">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
