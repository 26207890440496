import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';

// Import components and pages
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import PriestRegistration from './components/PriestRegistration';
import UserRegistration from './components/UserRegistration';
import AuthForm from './components/AuthForm';
import HomePage from './homepage';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

function AppContent() {
  return (
    <div className="App">
      <Header />
      <div className="App-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<AuthForm />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/priest-registration" element={<ProtectedRoute><PriestRegistration /></ProtectedRoute>} />
          <Route path="/user-registration" element={<UserRegistration />} />
          
          {/* Route redirection to homepage sections */}
          <Route path="/aboutus" element={<HomePage />} />
          <Route path="/pujaris" element={<HomePage />} />
          <Route path="/testimonials" element={<HomePage />} />
          
          {/* 404 Fallback Route */}
          <Route path="*" element={<h2>404: Page Not Found</h2>} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
