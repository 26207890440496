import React from 'react';
import './aboutus.css'; // Make sure to create and link this CSS file

function Aboutus() {
  return (
    <div className="about-us-page"> {/* Add this wrapper div with a new class */}
      <div className="about-us-container">
        <h1 className="about-us-title">About PujariNet</h1>
        
        <section className="about-us-section mission">
          <h2>Our Mission</h2>
          <p>PujariNet is dedicated to connecting families with qualified priests for their religious ceremonies, making the process simple, efficient, and stress-free.</p>
        </section>

        <section className="about-us-section how-it-works">
          <h2>How It Works</h2>
          <ol>
            <li>Create an account and specify your requirements</li>
            <li>Browse our extensive network of verified priests</li>
            <li>Filter by language, expertise, and availability</li>
            <li>Book your chosen priest directly through our platform</li>
            <li>Communicate and plan your ceremony with ease</li>
          </ol>
        </section>

        <section className="about-us-section why-choose-us">
          <h2>Why Choose PujariNet?</h2>
          <div className="features-grid">
            <div className="feature">
              <i className="fas fa-users"></i>
              <p>Largest network of verified priests</p>
            </div>
            <div className="feature">
              <i className="fas fa-calendar-check"></i>
              <p>Easy-to-use booking system</p>
            </div>
            <div className="feature">
              <i className="fas fa-dollar-sign"></i>
              <p>Transparent pricing, no hidden fees</p>
            </div>
            <div className="feature">
              <i className="fas fa-language"></i>
              <p>Multilingual support for diverse communities</p>
            </div>
            <div className="feature">
              <i className="fas fa-lock"></i>
              <p>Secure platform for communication and payments</p>
            </div>
            <div className="feature">
              <i className="fas fa-headset"></i>
              <p>24/7 customer support</p>
            </div>
          </div>
        </section>

        <section className="about-us-section contact-us">
          <h2>Get in Touch</h2>
          <p>Have questions or need assistance? Our team is here to help!</p>
          <button className="contact-button">Contact Us</button>
        </section>
      </div>
    </div>
  );
}

export default Aboutus;
