import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserRegistration.css';

const UserRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    isPriest: false,
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handle input changes for all fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL.replace(/\/+$/, '');
      const response = await fetch(`${apiUrl}/api/auth/register`, { // Add '/api' prefix
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || 'Registration failed');
      }
  
      // Registration successful, navigate to login page
      alert('Registration successful!');
      navigate('/login');
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.message || 'Registration failed, please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  };  

  return (
    <form onSubmit={handleSubmit}>
      <h2>Register</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
        required
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        required
      />
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Password"
        required
      />
      <label>
        Are you a priest?
        <input
          type="checkbox"
          name="isPriest"
          checked={formData.isPriest}
          onChange={handleChange}
        />
      </label>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Registering...' : 'Register'}
      </button>
    </form>
  );
};

export default UserRegistration;
