import React, { useEffect, useState } from 'react';
import './Dashboard.css';

const Dashboard = () => {
  const [priests, setPriests] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch priest profiles from the backend
  const fetchPriests = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token

    if (!token) {
      setError('You must be logged in to view this page.');
      setLoading(false);
      return;
    }

    try {
      // Ensure the base URL is correct and does not have trailing slashes
      const apiUrl = process.env.REACT_APP_API_URL.replace(/\/+$/, '');

      const response = await fetch(`${apiUrl}/api/priests`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the request header
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is OK
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch priests');
      }

      // Parse response data
      const data = await response.json();
      setPriests(data); // Set the list of priests
    } catch (error) {
      console.error('Error fetching priests:', error);
      setError('Unable to load priest profiles.');
    } finally {
      setLoading(false); // End loading state
    }
  };

  useEffect(() => {
    fetchPriests(); // Fetch priests when the component mounts
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      {loading ? (
        <p>Loading priests...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : priests.length > 0 ? (
        <div className="priests-grid">
          {priests.map((priest) => (
            <div key={priest._id} className="priest-card">
              <h3>{priest.name}</h3>
              <div className="priest-info">
                <p>Email: {priest.email}</p>
                <p>Specialty: {priest.specialty}</p>
                <p>Charges: ${priest.charges}</p>
                <p>Languages: {priest.language.join(', ')}</p>
              </div>
              <a href={`/book/${priest._id}`} className="book-now-button">
                Book Now
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p>No priests available.</p>
      )}
    </div>
  );
};

export default Dashboard;
