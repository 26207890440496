// src/components/PriestRegistration.js
import React, { useState } from 'react';
import axios from 'axios';

function PriestRegistration() {
  const [name, setName] = useState('');
  const [languages, setLanguages] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [radius, setRadius] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const priestData = {
      name,
      languages: languages.split(','),
      affiliation,
      specialty,
      radius
    };

    try {
      const response = await axios.post('/api/priest/register', priestData);
      alert('Priest registered successfully');
    } catch (error) {
      console.error('Error registering priest:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Priest Registration</h2>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Languages (comma separated):</label>
        <input
          type="text"
          value={languages}
          onChange={(e) => setLanguages(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Temple Affiliation:</label>
        <input
          type="text"
          value={affiliation}
          onChange={(e) => setAffiliation(e.target.value)}
        />
      </div>
      <div>
        <label>Specialty:</label>
        <input
          type="text"
          value={specialty}
          onChange={(e) => setSpecialty(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Travel Radius (in km):</label>
        <input
          type="number"
          value={radius}
          onChange={(e) => setRadius(e.target.value)}
          required
        />
      </div>
      <button type="submit">Register as Priest</button>
    </form>
  );
}

export default PriestRegistration;
